import { useGetArticles } from "modules/article/api";
import { Article } from "modules/article/types/article.interface";
import { useState } from "react";
import ArticleTable, { RowObjArticle } from "../default/components/articleTable";

const Articles = () => {
  const [searchName, setSearchName] = useState<string | undefined>(undefined);
  const { data, hasNextPage, fetchNextPage } = useGetArticles(searchName);
  const [currentPage, setCurrentPage] = useState(1);
  console.log(data);
  const flatData = data?.pages[currentPage - 1].data;

  const handleFormatData = (data: Article[]): RowObjArticle[] => {
    return data.map((item) => ({
      id: item._id,
      label: item.label,
      priceHT: item.priceHT ? item.priceHT.toString() : "-",
      priceTTC: item.priceTTC ? item.priceTTC.toString() : "-",
      code: item.code ? item.code: "-",
    }));
  };

  const handleFetchNextPage = () => {
    if (hasNextPage) {
      fetchNextPage();
      setCurrentPage(currentPage + 1);
      console.log(currentPage);
      console.log(flatData)
    }
  };

  const handleFetchPrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div>
      <div className="mt-5">
        {data?.pages.length > 0 && (
          <ArticleTable
            tableData={handleFormatData(flatData)}
            searchName={searchName}
            setSearchName={setSearchName}
            currentPage={currentPage}
            totalPage={data.pages[0].count}
            hasNextPage={hasNextPage}
            handleFetchNextPage={handleFetchNextPage}
            handleFetchPrevPage={handleFetchPrevPage}
          />
        )}
      </div>
    </div>
  );
};

export default Articles;
